<template>
  <div class="product-domain" v-bind="$attrs">
    <Card
      :thumbnail="item.image"
      :title="item.title"
      variant="horizontal"
      class="mt-2 core-broker-step-products__card"
      :class="{ 'core-broker-step-products__card--selected': item.isSelected }"
    >
      <template #default>
        <div v-html="item.description"></div>
        <Alert iconPlacement="left" class="mt-2">
          <template>
            <div v-html="item.alert"></div>
          </template>
        </Alert>
        <Alert iconPlacement="left" class="mt-2" v-if="item.currentDomain">
          <template>
            <div v-html="item.currentDomain"></div>
          </template>
        </Alert>
      </template>
      <template #actions>
        <div class="bold" v-html="item.price"></div>

        <Button
          @click="
            () => {
              if (item.disabled) return
              return (show = true)
            }
          "
          v-bind="getButtonProps(item.isSelected)"
          :class="{ 'elder-button--disabled': item.disabled }"
          v-tooltip="getTooltipProps(item)"
        />

        <Modal :show="show" :clickaway="true" @close="reset">
          <template #default="{ close }">
            <Card
              title="Dette trenger vi for å sette opp eget nettsted/domenenavn"
              variant="prompt"
              class="product-domain__card"
            >
              <template #default>
                <div>
                  For at vi skal kunne sette opp en egen URL struktur trenger vi et domene. Når domene er kjøpt vil det
                  være nødvendig at Kvass settes i dialog med IT-ansvarlig eller en sparringspartner som kan sette opp
                  riktig pekere hos deres leverandør.
                </div>

                <div>
                  <span class="bold">Har du kjøpt et domene som skal benyttes?</span>
                  <Radio
                    class="flex-col mt-1"
                    :items="[
                      { label: 'Ja', value: true },
                      { label: 'Nei', value: false },
                    ]"
                    v-model="value.domain.hasDomain"
                  />
                </div>

                <template v-if="value.domain.hasDomain">
                  <Input
                    required
                    label="URL som skal benyttes"
                    v-model="value.domain.url"
                    placeholder="For eksempel Urtehagen.no"
                    prefix="https://"
                  />

                  <Input
                    required
                    label="Teknisk kontaktperson for oppsett hos leverandør"
                    v-model="value.domain.contact"
                    placeholder="For eksempel IT ansvarlig, eller vedkommende som har kjøpt domene."
                  />
                  <Alert iconPlacement="left">
                    <template>
                      <div>
                        Vi vil nå ta kontakt med vedkommende oppgitt som teknisk kontaktperson for videre oppsett av
                        URL-struktur
                      </div>
                    </template>
                  </Alert>
                </template>
                <template v-else>
                  <Alert iconPlacement="left">
                    <template>
                      <div>
                        Vi kan ikke opprette et eget nettsted uten et domene. Dette kan også opprettes senere i
                        prosjektet. Kontakt <a :href="'mailto:support@kvass.no'">support@kvass.no</a> for mer
                        informasjon.
                      </div>
                    </template>
                  </Alert>
                </template>
              </template>
              <template #actions>
                <Button @click="close" label="Avbryt" />
                <Button
                  @click="submit"
                  label="Bekreft"
                  theme="primary"
                  :class="{ 'elder-button--disabled': !validator.passes }"
                  v-tooltip="{
                    content: validatorErrors,
                    placement: 'right',
                    delay: 0,
                    offet: 20,
                    classes: ['core-broker-step-products__tooltip'],
                  }"
                />
              </template>
            </Card>
          </template>
        </Modal>
      </template>
    </Card>
  </div>
</template>

<script>
import { ButtonComponent as Button } from 'vue-elder-button'
import { ModalComponent as Modal } from 'vue-elder-modal'
import { InputComponent as Input } from 'vue-elder-input'
import { AlertComponent as Alert } from 'vue-elder-alert'
import Radio from '@kvass/radio'
import Card from '@/components/Card-v2.vue'
import { toCurrency } from '@/utils'
import Validator from '@/mixins/validator-v2'

const ValidatorSettings = {
  labels: {
    hasDomain: 'Du må ha kjøpt et domene som kan benyttes',
    url: 'Fyll inn URL som skal benyttes',
    contact: 'Fyll inn teknisk kontaktperson',
  },
  rules: {
    hasDomain: 'accepted',
    url: [{ required_if: ['hasDomain', true] }],
    contact: [{ required_if: ['hasDomain', true] }],
  },
}

export default {
  inject: ['$payload'],
  mixins: [
    Validator({
      data: 'value.domain',
      labels: 'labels',
      rules: 'rules',
      customErrorMessages: {
        accepted: ':attribute',
        required: ':attribute',
        required_if: ':attribute',
      },
    }),
  ],
  props: {
    value: Object,
    getTooltipProps: Function,
    getButtonProps: Function,
  },

  data() {
    return {
      show: false,
      labels: ValidatorSettings.labels,
      rules: ValidatorSettings.rules,
    }
  },

  methods: {
    reset() {
      this.show = false

      this.value.domain.selected = false
    },

    submit() {
      if (!this.validator.passes) return

      this.value.domain.selected = true

      this.show = false
    },
  },

  computed: {
    validatorErrors() {
      return this.validator.passes
        ? ''
        : Object.values(this.validator.errors.errors)
            .flat()
            .join('<br>')
    },
    payload() {
      return this.$payload()
    },
    item() {
      const {
        homepage: { url, label },
        project: { priceBroker, domain: pDomain },
        upcoming: { domain: uDomain },
      } = this.payload

      const currentDomain = (pDomain || uDomain || {}).url

      const getPrice = () => {
        if (currentDomain) return ''
        return priceBroker ? `${toCurrency(priceBroker.domain.value)} pr. påbegynte time` : 'Pris iht. avtale'
      }

      const title = 'Oppsett av nettsted med eget domene'
      return {
        value: true,
        image: 'https://assets.kvass.no/6728e0025a53e9251b498a1d',
        title,
        description: `Å ha et eget nettsted betyr at prosjektet får en dedikert nettadresse/domene.
            Noe som gjør det lettere å markedsføre og få tilgang til prosjektet, for eksempel via fasadeseil eller QR-koder.
            Prosjektet vil bli publisert på eget nettsted og under <a target="_blank" href="${url}" class="bold">${label}</a> nettside. Dette kan også settes opp ved en senere anledning.`,
        disabled: !this.value.projectType || currentDomain,
        price: getPrice(),
        alert: `Som standard idag benyttes domene under <a target="_blank" href="${url}" class="bold">${label}</a> nettside.`,
        isSelected: this.value.domain.selected || currentDomain,
        disabledText: currentDomain
          ? `Du har allerede bestilt domene`
          : `Du må velge "Prosjektside" for å kunne velge "${title}"`,
        currentDomain: currentDomain
          ? `Du har allerede bestilt domenet <a target="_blank" href="https://${currentDomain}" class="bold">${currentDomain}</a>, og så snart du publiserer prosjektsiden din, vil domenet automatisk bli overført fra "kommer for salg"-siden.`
          : null,
      }
    },
  },

  components: {
    Button,
    Card,
    Modal,
    Input,
    Radio,
    Alert,
  },
}
</script>

<style lang="scss">
.product-domain {
  &__card {
    max-width: 700px;

    .card-v2__content {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
    }
    .card-v2__actions {
      justify-content: center !important;
    }
  }
}
</style>
