<template>
  <Card
    title="Bestill nytt prosjekt"
    subtitle="Informasjonen presentert nedenfor er hentet direkte fra fagsystemet."
    variant="prompt"
  >
    <template #default>
      <div class="grid-2 gap-4">
        <div class="flex-col gap-2">
          <h3 class="mb-1">Informasjon fra fagsystemet</h3>

          <Input :value="payload.name" label="Prosjektnavn i fagsystem" :disabled="true" />
          <Input :value="payload.referenceId" label="Prosjekt ID" :disabled="true" />
          <Input :value="payload.brokerCompany" label="Meglerkjede og kontor" :disabled="true" />
          <Input :value="payload.brokers" label="Eiendomsmegler(e)" :disabled="true" />
          <Input :value="payload.broker.name" label="Bestiller av Kvass prosjekt" :disabled="true" />
        </div>

        <div class="flex-col gap-2">
          <h3 class="mb-1">Informasjon for opprettelse i Kvass</h3>

          <!-- 
          Temporarily disabled the function to overwrite the project name in Kvass during project ordering, 
          due to potential confusion that this name might be assumed to be the main presentation headline of the project page.

           <Input v-model="value.name" label="Prosjektnavn til prosjektside" />
            <Alert iconPlacement="left">
              Prosjektet presenteres på prosjektsiden med navnet som er skrevet i feltet over. Her kan du overskrive
              dette, om navnet i fagsystemet ikke er passende.
           </Alert> 
          -->

          <Input :value="payload.units" label="Antall enheter (hentet fra fagsystem)" :disabled="true" />

          <Alert v-if="payload.project.priceBroker" iconPlacement="left">
            Basert på {{ payload.units }} enheter tildeldes du pakke <b>"{{ payload.project.priceBroker.label }}"</b>.
            Ønsker du mer informasjon, ta kontakt med
            <a href="mailto:support@kvass.no">support@kvass.no</a>
          </Alert>

          <Alert v-else iconPlacement="left">
            <a href="mailto:support@kvass.no">support@kvass.no</a> vi ta kontakt med deg for oppsett av prosjektet med
            {{ payload.units }} enheter
          </Alert>
        </div>
      </div>
    </template>
    <template #actions>
      <Button label="Neste" theme="primary" :disabled="disabled" @click="submit" icon="fa-pro-solid:arrow-right-long" />
    </template>
  </Card>
</template>

<script>
import Validator from '@/mixins/validator-v2'
import Card from '@/components/Card-v2.vue'
import { InputComponent as Input } from 'vue-elder-input'
import { ButtonComponent as Button } from 'vue-elder-button'
import { AlertComponent as Alert } from 'vue-elder-alert'

const ValidatorSettings = {
  labels: {
    name: 'Prosjektnavn',
  },
  rules: {
    name: 'required',
  },
}

export { ValidatorSettings }
export default {
  inject: ['$isAdmin'],
  mixins: [
    Validator({
      data: 'value',
      labels: 'labels',
      rules: 'rules',
    }),
  ],
  props: {
    payload: Object,
    value: Object,
  },
  data() {
    return {
      promise: null,
      labels: ValidatorSettings.labels,
      rules: ValidatorSettings.rules,
    }
  },
  computed: {
    isAdmin() {
      return this.$isAdmin()
    },
    disabled() {
      if (!this.validator.passes) return true
      if (this.isAdmin) return false
      return Boolean(!this.payload.project.priceBroker)
    },
  },
  methods: {
    submit() {
      if (!this.validator.passes) return

      return this.$emit('navigate', 'products')
    },
  },
  components: {
    Input,
    Button,
    Card,
    Alert,
  },
}
</script>

<style lang="scss"></style>
