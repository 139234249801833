var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._b({staticClass:"product-domain"},'div',_vm.$attrs,false),[_c('Card',{staticClass:"mt-2 core-broker-step-products__card",class:{ 'core-broker-step-products__card--selected': _vm.item.isSelected },attrs:{"thumbnail":_vm.item.image,"title":_vm.item.title,"variant":"horizontal"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.item.description)}}),_c('Alert',{staticClass:"mt-2",attrs:{"iconPlacement":"left"}},[[_c('div',{domProps:{"innerHTML":_vm._s(_vm.item.alert)}})]],2),(_vm.item.currentDomain)?_c('Alert',{staticClass:"mt-2",attrs:{"iconPlacement":"left"}},[[_c('div',{domProps:{"innerHTML":_vm._s(_vm.item.currentDomain)}})]],2):_vm._e()]},proxy:true},{key:"actions",fn:function(){return [_c('div',{staticClass:"bold",domProps:{"innerHTML":_vm._s(_vm.item.price)}}),_c('Button',_vm._b({directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.getTooltipProps(_vm.item)),expression:"getTooltipProps(item)"}],class:{ 'elder-button--disabled': _vm.item.disabled },on:{"click":function () {
            if (_vm.item.disabled) { return }
            return (_vm.show = true)
          }}},'Button',_vm.getButtonProps(_vm.item.isSelected),false)),_c('Modal',{attrs:{"show":_vm.show,"clickaway":true},on:{"close":_vm.reset},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var close = ref.close;
return [_c('Card',{staticClass:"product-domain__card",attrs:{"title":"Dette trenger vi for å sette opp eget nettsted/domenenavn","variant":"prompt"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',[_vm._v(" For at vi skal kunne sette opp en egen URL struktur trenger vi et domene. Når domene er kjøpt vil det være nødvendig at Kvass settes i dialog med IT-ansvarlig eller en sparringspartner som kan sette opp riktig pekere hos deres leverandør. ")]),_c('div',[_c('span',{staticClass:"bold"},[_vm._v("Har du kjøpt et domene som skal benyttes?")]),_c('Radio',{staticClass:"flex-col mt-1",attrs:{"items":[
                    { label: 'Ja', value: true },
                    { label: 'Nei', value: false } ]},model:{value:(_vm.value.domain.hasDomain),callback:function ($$v) {_vm.$set(_vm.value.domain, "hasDomain", $$v)},expression:"value.domain.hasDomain"}})],1),(_vm.value.domain.hasDomain)?[_c('Input',{attrs:{"required":"","label":"URL som skal benyttes","placeholder":"For eksempel Urtehagen.no","prefix":"https://"},model:{value:(_vm.value.domain.url),callback:function ($$v) {_vm.$set(_vm.value.domain, "url", $$v)},expression:"value.domain.url"}}),_c('Input',{attrs:{"required":"","label":"Teknisk kontaktperson for oppsett hos leverandør","placeholder":"For eksempel IT ansvarlig, eller vedkommende som har kjøpt domene."},model:{value:(_vm.value.domain.contact),callback:function ($$v) {_vm.$set(_vm.value.domain, "contact", $$v)},expression:"value.domain.contact"}}),_c('Alert',{attrs:{"iconPlacement":"left"}},[[_c('div',[_vm._v(" Vi vil nå ta kontakt med vedkommende oppgitt som teknisk kontaktperson for videre oppsett av URL-struktur ")])]],2)]:[_c('Alert',{attrs:{"iconPlacement":"left"}},[[_c('div',[_vm._v(" Vi kan ikke opprette et eget nettsted uten et domene. Dette kan også opprettes senere i prosjektet. Kontakt "),_c('a',{attrs:{"href":'mailto:support@kvass.no'}},[_vm._v("support@kvass.no")]),_vm._v(" for mer informasjon. ")])]],2)]]},proxy:true},{key:"actions",fn:function(){return [_c('Button',{attrs:{"label":"Avbryt"},on:{"click":close}}),_c('Button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                  content: _vm.validatorErrors,
                  placement: 'right',
                  delay: 0,
                  offet: 20,
                  classes: ['core-broker-step-products__tooltip'],
                }),expression:"{\n                  content: validatorErrors,\n                  placement: 'right',\n                  delay: 0,\n                  offet: 20,\n                  classes: ['core-broker-step-products__tooltip'],\n                }"}],class:{ 'elder-button--disabled': !_vm.validator.passes },attrs:{"label":"Bekreft","theme":"primary"},on:{"click":_vm.submit}})]},proxy:true}],null,true)})]}}])})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }