<template>
  <Card
    variant="prompt"
    title="Velg produkter"
    subtitle="Her kan du velge tilgjengelige produkter"
    class="core-broker-step-products"
  >
    <template #default>
      <div class="grid-2 gap-4">
        <Card
          v-for="item in projectTypes"
          :thumbnail="item.image"
          :title="item.title"
          :class="{ 'core-broker-step-products__card--selected': item.isSelected }"
        >
          <template #default>
            <div>{{ item.description }}</div>
          </template>

          <template #actions>
            <div class="bold">{{ item.price }}</div>
            <Button
              @click="() => (item.disabled ? {} : setProjectType(item.value))"
              v-bind="getButtonProps(item.isSelected)"
              :class="{ 'elder-button--disabled': item.disabled }"
              v-tooltip="getTooltipProps(item)"
            />
          </template>
        </Card>
      </div>

      <template v-for="item in flatfinder" v-if="showProjectAddons">
        <Card
          :thumbnail="item.image"
          :title="item.title"
          variant="horizontal"
          class="mt-4 core-broker-step-products__card"
          :class="{ 'core-broker-step-products__card--selected': item.isSelected }"
        >
          <template #default>
            <div>{{ item.description }}</div>
            <Alert iconPlacement="left" class="mt-2">{{ item.alert }}</Alert>
          </template>
          <template #actions>
            <div class="bold">{{ item.price }}</div>
            <Button
              @click="() => (item.disabled ? {} : setFlatfinder(item.value))"
              v-bind="getButtonProps(item.isSelected)"
              :class="{ 'elder-button--disabled': item.disabled }"
              v-tooltip="getTooltipProps(item)"
            />
          </template>
        </Card>
      </template>

      <h2 class="mt-6" v-if="value.projectType">Tilleggsprodukter</h2>

      <FlatfinderSetup
        v-model="value"
        :getTooltipProps="getTooltipProps"
        :getButtonProps="getButtonProps"
        class="mt-2"
        v-if="showProjectAddons"
      />

      <Domain
        v-if="value.projectType"
        v-model="value"
        :getTooltipProps="getTooltipProps"
        :getButtonProps="getButtonProps"
        class="mt-4"
      />

      <template v-if="value.projectType">
        <h2 class="mt-6">Kostnadsmatrise</h2>
        <PricingTable
          :showFlatfinder="value.flatfinder.setup"
          :showDomain="value.domain.selected"
          :priceBroker="value.projectType === 'upcoming' ? payload.upcoming.priceBroker : payload.project.priceBroker"
          :priceCustomer="
            value.projectType === 'upcoming' ? payload.upcoming.priceCustomer : payload.project.priceCustomer
          "
          :angles="value.flatfinder.angles"
          :projectType="value.projectType"
          :costPriceConfig="payload.costPriceConfig"
        ></PricingTable>
      </template>
    </template>
    <template #actions>
      <Button label="Forrige" @click="prev" />
      <Button
        @click="submit"
        label="Neste"
        theme="primary"
        icon="fa-pro-solid:arrow-right-long"
        :class="{ 'elder-button--disabled': !validator.passes || (!payload.project.priceBroker && !isAdmin) }"
        v-tooltip="{
          content: getValidatorErrors,
          placement: 'right',
          delay: 0,
          offet: 20,
          classes: ['core-broker-step-products__tooltip'],
        }"
      />
    </template>
  </Card>
</template>

<script>
import Validator from '@/mixins/validator-v2'
import Card from '@/components/Card-v2.vue'
import { ButtonComponent as Button } from 'vue-elder-button'
import { AlertComponent as Alert } from 'vue-elder-alert'

import { toCurrency } from '@/utils'

import PricingTable from '../products/pricingTable.vue'
import Domain from '../products/domain.vue'
import FlatfinderSetup from '../products/flatfinderSetup.vue'

const ValidatorSettings = {
  labels: {
    projectType: 'Velg enten "Kommer for salg side" eller "Prosjektside"',
  },
  rules: {
    projectType: 'required',
  },
}

export { ValidatorSettings }
export default {
  inject: ['$isAdmin'],
  mixins: [
    Validator({
      data: 'value',
      labels: 'labels',
      rules: 'rules',
      customErrorMessages: {
        required: ':attribute',
        required_if: ':attribute',
      },
    }),
  ],
  props: {
    payload: Object,
    value: Object,
  },
  data() {
    return {
      labels: ValidatorSettings.labels,
      rules: ValidatorSettings.rules,
    }
  },
  computed: {
    isAdmin() {
      return this.$isAdmin()
    },
    getValidatorErrors() {
      return this.validator.passes
        ? ''
        : Object.values(this.validator.errors.errors)
            .flat()
            .join('<br>')
    },

    projectTypes() {
      const { upcoming, project, hasUpcoming, hasProject, units } = this.payload

      const upcomingTitle = 'Kommer for salg side'
      const projectTitle = 'Prosjektside'

      return [
        {
          value: 'upcoming',
          image: upcoming.image || 'https://assets.kvass.no/660eb30beae6b96b45b0f1af',
          title: 'Kommer for salg side',
          description:
            'Kommer for salg side er en enkel side uten boligvelger og enheter, denne brukes gjerne i forkant for å presentere prosjektet i markedet. Her kan du få interessenter og sikre at du har en god liste med potensielle kjøpere før prosjektet kommer formelt i gang.',
          price: upcoming.priceBroker ? toCurrency(upcoming.priceBroker.project.value) : 'Pris iht. avtale',
          disabled: hasUpcoming || hasProject || (!upcoming.priceBroker && !this.isAdmin),
          isSelected: this.value.projectType === 'upcoming',
          disabledText: `"${hasProject ? projectTitle : upcomingTitle}" allerede bestillt`,
        },
        {
          value: 'project',
          image: project.image || 'https://assets.kvass.no/660eb30beae6b96b45b0f1af',
          title: projectTitle,
          description:
            'Prosjektside er en større side som alltid har en enhetsliste med alle tilgjengelige enheter. Her kan man også skrive mer informasjon om hver enkelt enhet. Det er også mulig å legge til tekst om for eksempel området, mulige tilvalg eller nyheter. Dette bestemmer du selv.',
          price: project.priceBroker ? toCurrency(project.priceBroker.project.value) : 'Pris iht. avtale',
          disabled: units === 0 || hasProject || (!project.priceBroker && !this.isAdmin),
          isSelected: this.value.projectType === 'project',
          disabledText:
            units === 0
              ? `Du må ha enheter for å bestille en "${projectTitle}"`
              : `"${projectTitle}" allerede bestillt`,
        },
      ]
    },

    showProjectAddons() {
      const p = this.projectTypes.find(v => v.value === 'project')
      if (!p) return
      return !p.disabled && p.isSelected
    },

    flatfinder() {
      return [
        {
          value: true,
          image: 'https://assets.kvass.no/67050f0badb25f703a93022b',
          title: 'Boligvelger',
          description: 'Boligvelger er inkludert på alle våre prosjektside maler.',
          disabled: true,
          price: `Inkludert`,
          alert: 'Vi har som standard på alle våre maler boligvelger.',
          isSelected: this.value.flatfinder.selected,
          disabledText:
            this.value.projectType !== 'project'
              ? 'Du må bestille "Prosjektside" for å kunne velge "Boligvelger"'
              : '"Boligvelger" har vi som standard på våre prosjektsider og kan ikke hukes bort',
        },
      ]
    },

    flatFinderSetup() {
      const {
        project: { priceBroker },
      } = this.payload

      return [
        {
          value: true,
          image: 'https://assets.kvass.no/67050f31adb25f703a930237',
          title: 'Oppsett av boligvelger',
          description: 'Om du ønsker at vi setter opp din boligvelger kan du velge dette tilleggsproduktet.',
          disabled: !this.value.flatfinder.selected || this.value.projectType !== 'project',
          price: priceBroker ? `${toCurrency(priceBroker.angle.value)} pr vinkel` : 'Pris iht. avtale',
          alert: 'Oppsett av boligvelger tar ca 2 virkedager.',
          isSelected: this.value.flatfinder.setup,
          disabledText: 'Du må bestille "Boligvelger" for å kunne velge "Oppsett av boligvelger"',
        },
      ]
    },
  },
  methods: {
    toCurrency,
    setProjectType(type) {
      const isSame = this.value.projectType === type

      if (isSame || type !== 'project') {
        this.value.flatfinder.selected = null
        this.value.flatfinder.setup = null
      }

      if (!isSame && type === 'project') {
        this.value.flatfinder.selected = true
      }

      this.value.projectType = isSame ? null : type
    },

    setFlatfinder(type) {
      const isSame = this.value.flatfinder.selected === type

      if (isSame) {
        this.value.flatfinder.setup = null
      }

      this.value.flatfinder.selected = isSame ? null : type
    },

    setFlatfinderSetup(type) {
      this.value.flatfinder.setup = this.value.flatfinder.setup === type ? null : type
    },

    getTooltipProps(item) {
      return item.disabled
        ? {
            content: item.disabledText,
            placement: 'right',
            delay: 0,
            offset: 20,
            classes: ['core-broker-step-products__tooltip'],
          }
        : null
    },

    getButtonProps(selected) {
      return selected
        ? {
            label: 'Valgt',
            icon: 'fa-pro-solid:check',
            theme: 'primary',
          }
        : {
            label: 'Velg',
            theme: 'default',
          }
    },

    prev() {
      return this.$emit('navigate', 'order')
    },
    submit() {
      if (!this.validator.passes || (!this.payload.project.priceBroker && !this.isAdmin)) return

      return this.$emit('navigate', 'subscription')
    },
  },
  components: {
    Button,
    Card,
    Alert,
    PricingTable,
    Domain,
    FlatfinderSetup,
  },
}
</script>

<style lang="scss">
.core-broker-step-products {
  .card-v2 {
    .card-v2__actions {
      justify-content: space-between;

      @include respond-below('tablet') {
        flex-wrap: wrap;
      }
    }
  }

  &__card {
    .card-v2__actions {
      background-color: white;
    }
    &--selected {
      .card-v2__actions {
        background-color: var(--lightest-grey);
      }
    }
  }
}
</style>
