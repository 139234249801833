<template>
  <div class="product-flatfinder-setup" v-bind="$attrs">
    <Card
      :thumbnail="item.image"
      :title="item.title"
      variant="horizontal"
      class="core-broker-step-products__card"
      :class="{ 'core-broker-step-products__card--selected': item.isSelected }"
    >
      <template #default>
        <div v-html="item.description"></div>
        <Alert iconPlacement="left" class="mt-2">
          <template>
            <div v-html="item.alert"></div>
          </template>
        </Alert>
      </template>
      <template #actions>
        <div class="bold" v-html="item.price"></div>

        <div class="flex-row gap-1 flex-wrap">
          <Angles
            v-model="value.flatfinder.angles"
            @input="() => (item.isSelected ? {} : setFlatfinderSetup(item.value))"
            :disabled="item.disabled"
            v-tooltip="getTooltipProps(item)"
          />

          <Button
            @click="
              () => {
                if (item.disabled) return
                return (show = true)
              }
            "
            v-bind="getButtonProps(item.isSelected)"
            :class="{ 'elder-button--disabled': item.disabled }"
            v-tooltip="getTooltipProps(item)"
          />
        </div>

        <Modal :show="show" :clickaway="true" @close="reset">
          <template #default="{ close }">
            <Card
              title="Dette trenger vi for å lage boligvelger"
              variant="prompt"
              class="product-flatfinder-setup__card"
            >
              <template #default>
                <div>
                  Vi trenger først et 3D bilde av prosjektet, deretter en markering av hvilke enheter som hører til
                  hvor. Markering kan fint tegnes av melger med for eksempel Power-point.
                </div>

                <div class="grid-2 gap-4">
                  <template v-for="i in examples">
                    <div class="gap-1 flex-col">
                      <b>{{ i.title }}</b>
                      <img :style="{ width: '100%', 'aspect-ratio': 4 / 3 }" :src="i.image" />
                    </div>
                  </template>
                </div>

                <Card title="Last opp 2 eller flere filer til boligvelger" variant="widget">
                  <template #default>
                    <File v-model="value.flatfinder.images" :upload="upload" label="" :multiple="true" accept="image/*">
                      <template #icon="{ item }">
                        <img :src="item.url" :alt="item.name" />
                      </template>
                    </File>
                  </template>
                </Card>

                <Input label="Valgfri kommentar">
                  <textarea
                    class="elder-input__element"
                    v-model="value.flatfinder.comment"
                    rows="2"
                    placeholder="Om du ønsker å fortelle noe ekstra, skriv det gjerne her."
                  />
                </Input>
              </template>
              <template #actions>
                <Button @click="close" label="Avbryt" />
                <Button
                  @click="submit"
                  label="Bekreft"
                  theme="primary"
                  :class="{ 'elder-button--disabled': !validator.passes }"
                  v-tooltip="{
                    content: validatorErrors,
                    placement: 'right',
                    delay: 0,
                    offet: 20,
                    classes: ['core-broker-step-products__tooltip'],
                  }"
                />
              </template>
            </Card>
          </template>
        </Modal>
      </template>
    </Card>
  </div>
</template>

<script>
import { VueComponentUpload as Upload } from '@kvass/storage'
import { ButtonComponent as Button } from 'vue-elder-button'
import { ModalComponent as Modal } from 'vue-elder-modal'
import { InputComponent as Input } from 'vue-elder-input'
import { AlertComponent as Alert } from 'vue-elder-alert'
import { FileComponent as File, setup as FileSetup } from 'vue-elder-file'

import Radio from '@kvass/radio'
import Card from '@/components/Card-v2.vue'
import { toCurrency } from '@/utils'
import Validator from '@/mixins/validator-v2'

import Angles from './angles.vue'

const ValidatorSettings = {
  labels: {
    'images.1.url': 'Last opp 2 eller flere filer til boligvelger',
  },
  rules: {
    'images.1.url': 'required',
  },
}

FileSetup({
  dropMessage: 'Dra en fil hit eller <b>velg</b> for å laste opp',
})

export default {
  inject: ['$payload'],
  mixins: [
    Validator({
      data: 'value.flatfinder',
      labels: 'labels',
      rules: 'rules',
      customErrorMessages: {
        required: ':attribute',
      },
    }),
  ],
  props: {
    value: Object,
    getTooltipProps: Function,
    getButtonProps: Function,
  },

  data() {
    return {
      show: false,
      labels: ValidatorSettings.labels,
      rules: ValidatorSettings.rules,
    }
  },

  methods: {
    reset() {
      this.show = false
      this.value.flatfinder.setup = false
    },

    upload(file, onProgress, options) {
      return Upload(file, onProgress, {
        ...options,
        baseURL: `https://${this.payload.tenant}`,
        headers: {
          Authorization: `Kvass ${this.payload.uploadToken}`,
        },
        credentials: 'omit',
      })
    },

    submit() {
      if (!this.validator.passes) return
      this.value.flatfinder.setup = true
      this.show = false
    },
  },

  computed: {
    validatorErrors() {
      return this.validator.passes
        ? ''
        : Object.values(this.validator.errors.errors)
            .flat()
            .join('<br>')
    },
    payload() {
      return this.$payload()
    },
    examples() {
      return [
        {
          title: '3D bilde til boligvelger',
          image: 'https://assets.kvass.no/670624b893f4321ec07ef854',
        },
        {
          title: 'Bilde med markerte enheter',
          image: 'https://assets.kvass.no/670624d793f4321ec07ef860',
        },
      ]
    },
    item() {
      const {
        project: { priceBroker },
      } = this.payload

      const title = 'Oppsett av boligvelger'

      return {
        value: true,
        image: 'https://assets.kvass.no/67050f31adb25f703a930237',
        title,
        description: 'Om du ønsker at vi setter opp din boligvelger kan du velge dette tilleggsproduktet.',
        disabled: !this.value.flatfinder.selected || this.value.projectType !== 'project',
        price: priceBroker ? `${toCurrency(priceBroker.angle.value)} pr vinkel` : 'Pris iht. avtale',
        alert: 'Oppsett av boligvelger tar ca 2 virkedager.',
        isSelected: this.value.flatfinder.setup,
        disabledText: `Du må bestille "Boligvelger" for å kunne velge "${title}"`,
      }
    },
  },

  components: {
    Button,
    Card,
    Modal,
    Input,
    Radio,
    File,
    Alert,
    Angles,
  },
}
</script>

<style lang="scss">
.product-flatfinder-setup {
  &__card {
    max-width: 700px;

    .elder-file__thumbnail-icon {
      img {
        aspect-ratio: 1/1;
        max-width: 80px;
        max-height: 80px;
        object-fit: contain;
      }
    }

    .card-v2__content {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
    }
    .card-v2__actions {
      justify-content: center !important;
    }
  }
}
</style>
